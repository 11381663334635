// trackEvent.js
'use strict';

export const MESSAGE_SENT_FAILED = 'MESSAGE_SENT_FAILED';
export const SHOP_OPENED = 'SHOP_OPENED';
export const SHOP_CLOSED = 'SHOP_CLOSED';

const isServer = typeof window === 'undefined';

/**
 * Send event to event trackers.
 * @module trackEvent
 */
export const trackEvent = async ({ type, payload }) => {
  if (isServer) return;
  await new Promise(window.requestIdleCallback);
  switch (type) {
    case MESSAGE_SENT_FAILED:
      return _MESSAGE_SENT_FAILED(payload);
    case SHOP_OPENED:
      return _SHOP_OPENED(payload);
    case SHOP_CLOSED:
      return _SHOP_CLOSED(payload);
    default:
      return;
  }
};

/**
 * Handle MESSAGE_SENT_FAILED event.
 * @kind trackEvent/eventType
 * @name MESSAGE_SENT_FAILED
 * @param {string} { reason } - the error reason
 * @param {string} { type } - the message type
 * @param {string} { id } - the message id
 * @param {string} { mediaType } - the message media type
 */
const _MESSAGE_SENT_FAILED = ({ reason, type }) => {
  if ('reply' === type && 'diamond_insufficient' === reason) {
    window.Intercom?.('trackEvent', 'reply_diamond_insufficient');
  }
};

/**
 * Handle SHOP_OPENED event.
 * @kind trackEvent/eventType
 * @name SHOP_OPENED
 * @param {object} payload
 */
const _SHOP_OPENED = payload => {
  window.Intercom?.('trackEvent', 'shop_opened', payload);
};

/**
 * Handle SHOP_CLOSED event.
 * @kind trackEvent/eventType
 * @name SHOP_CLOSED
 * @param {object} payload
 */
const _SHOP_CLOSED = payload => {
  window.Intercom?.('trackEvent', 'shop_closed', payload);
};

export default trackEvent;
